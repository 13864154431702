<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 moholand-shadow rounded-lg">
          <v-card-title>
            درخواست برداشت پول
            <v-spacer></v-spacer>

            <v-btn color="primary" :to="destinationRoute">
              <v-icon>mdi-undo-variant</v-icon>
              بازگشت
            </v-btn>
          </v-card-title>

          <v-form ref="form" @submit.prevent="submit" class="mt-2">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  class="rounded-lg"
                  text-color="white"
                  label
                  block
                  large
                  color="success"
                  elevation="0"
                >
                  موجودی فعلی:
                  {{
                    Intl.NumberFormat().format($store.state.userData.balance)
                  }}
                  تومان
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  class="rounded-lg"
                  text-color="white"
                  label
                  block
                  large
                  color="success"
                  elevation="0"
                >
                  حداقل مقدار درخواست: 150,000 تومان
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">مقدار درخواست برداشتی</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg"
                    :rules="[rules.required, rules.numeric, priceRule]"
                    v-model="fixPrice"
                    suffix="تومان"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">کارت بانکی</div>
                  <v-select
                    v-model="model.bank_id"
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    :items="items"
                    item-value="id"
                    item-text="name"
                    hint="در صورت اینکه بانکی تعریف نکردید،  لطفا ابتدا بانک تعریف کنید."
                    persistent-hint
                    flat
                    class="rounded-lg"
                    :loading="banksLoading"
                    :rules="[rules.required]"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="3">
                <v-btn
                  class="my-4 white--text moholand-rounded font-weight-bold"
                  elevation="0"
                  color="info"
                  block
                  x-large
                  to="/banksVendor/create"
                >
                  ثبت اطلاعات بانکی
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="3">
                <v-btn
                  class="my-4 white--text moholand-rounded font-weight-bold"
                  elevation="0"
                  color="orange"
                  block
                  x-large
                  type="submit"
                  :loading="loading"
                >
                  ثبت درخواست
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import { Constants } from "@/constants/GlobalConstants";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import moment from "jalali-moment";
export default {
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      banksLoading: false,
      items: [],
      model: {
        price: "",
        bank_id: null,
      },
      priceRule: (v) =>
        !v ||
        v.replace(",", "") <= this.$store.state.userData.balance ||
        "مبلغ مورد نظر از حد مجاز بیشتر است.",
    };
  },
  created() {
    this.GetList();
  },
  computed: {
    fixPrice: {
      get() {
        return this.model.price;
      },
      set(t) {
        if (!/(^[0-9|,]*$)/.test(t)) {
          this.model.price = t;
          return;
        }
        if (!t) this.model.price = "";
        if (t.includes(",")) t = t.split(",").join("");
        this.model.price = "" + Intl.NumberFormat().format(+t);
      },
    },
  },
  methods: {
    // onSelectMax(){
    //   this.fixPrice = this.$store.state.userData.balance.toString();
    //   this.model.price = this.$store.state.userData.balance.toString();
    // },
    onPriceChanged(value) {
      this.model.price = value;
    },
    GetList() {
      if (!this.banksLoading) this.banksLoading = true;

      MyAxios.get("/banks/index", {
        params: {
          conditions: {
            user_id: this.$store.state?.userData?.id || 0,
          },
        },
      })
        .then((response) => {
          this.banksLoading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.banksLoading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        const price = this.fixPrice.split(",").join("");
        if (price > this.$store.state.userData?.balance) {
          this.$root.$emit("toast", {
            text: "مبلغ برداشتی نباید بیشتر از اعتبار باشد.",
          });
          return;
        }
        if (price < 150000) {
          this.$root.$emit("toast", {
            text: "حداقل مقدار برداشت باید بیشتر از 150 هزار تومان باشد.",
          });
          return;
        }
        this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("user_id", this.$store.state?.userData?.id);
        data.append("date", moment(new Date()).format("YYYY-MM-DD"));
        data.append("price", price);

        MyAxios.post("withdraws/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "درخواست برداشت با موفقیت ایجاد شد.",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
            console.log(response);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
